import { Button } from '@mui/material'
import React, { Fragment, useEffect } from 'react'
import { iconsByCategory } from './result-card'
import { Script } from 'gatsby'
import { Check } from '@mui/icons-material'

const everyWebhookToken = 'lcnjTGepT0ZOsdci4C5ASEd86Tf8cOyp'

export const EveryDonateButton = ({
  organization,
  action_id,
  isCompleted
}: {
  organization: string
  action_id?: string
  isCompleted?: boolean
}) => {
  const metadata = {
    action_id
  }

  const encodedMetaData = btoa(JSON.stringify(metadata))

  return (
    <Fragment>
      <Button
        variant="contained"
        href={`https://staging.every.org/${organization}#/donate?webhook_token=${everyWebhookToken}&partner_metadata=${encodedMetaData}`}
        disableElevation
        color={isCompleted ? 'success' : 'primary'}
        startIcon={isCompleted ? <Check fontSize="large" /> : iconsByCategory['donate']}
        sx={{ flex: 1 }}
      >
        Donate
      </Button>
      <Script
        async
        defer
        src="https://embeds.every.org/0.4/button.js"
        id="every-donate-btn-js"
      ></Script>
    </Fragment>
  )
}
